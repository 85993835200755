import React from 'react'
import HomePage from './index'
import { graphql } from 'gatsby'

type Props = {
  data: any;
};

const NotFoundPage = ({ data }: Props) => {
  return (
    <HomePage data={data} />
  )
}

export default NotFoundPage

export const query = graphql`
{
  contentYaml(
    fields: {fileName: {eq: "settings-site-metadata"}, parentFolder: {eq: "content"}}
  ) {
    title
    keywords
    description
  }
}
`